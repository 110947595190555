import { Mail, Linkedin, MapPin } from 'lucide-react';

const ContactUs = () => {
  return (
    <footer className="bg-gray-900 text-white py-16">
      <div className="container mx-auto px-4">
        <div className="mb-12">
          <h2 className="text-4xl font-bold mb-4">
            Stay Informed: Follow our ESG journey and product developments
          </h2>
          <div className="h-1 w-20 bg-green-300"></div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div className="space-y-6">
            <div className="flex items-center space-x-4">
              <Mail className="text-green-300" size={24} />
              <div>
                <a href="mailto:travis.lck@alpha-eco.co" className="hover:text-green-300 transition-colors">
                  travis.lck@alpha-eco.co
                </a>
                <br />
                <a href="mailto:samtsang@alpha-eco.co" className="hover:text-green-300 transition-colors">
                  samtsang@alpha-eco.co
                </a>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Linkedin className="text-green-300" size={24} />
              <a href="https://www.linkedin.com/company/alpha-eco-hk/" target="_blank" rel="noopener noreferrer" className="hover:text-green-300 transition-colors">
                Alpha Eco
              </a>
            </div>
            <div className="flex items-start space-x-4">
              <MapPin className="text-green-300 mt-1 flex-shrink-0" size={24} />
              <p className="text-sm">
              <a href="https://maps.app.goo.gl/7XyQ9a5S9CMEGAKG6" target="_blank" rel="noopener noreferrer" className="hover:text-green-300 transition-colors">
                Co-work13, Unit 535, 5/F, Building 19W, 19 Science Park West Avenue, 
                Hong Kong Science Park, Pak Shek Kok, New Territories, Hong Kong              
              </a>
              </p>
            </div>
          </div>
          
          <div>
            <img src="/AEBG1.png" alt="Alpha Eco Background" className="w-full h-auto rounded-lg" />
          </div>
        </div>
        
        <div className="mt-16 pt-8 border-t border-gray-800 text-center">
          <p className="text-sm text-gray-400">
            © 2024 Alpha Eco, All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default ContactUs;