import { Button } from "@nextui-org/react";

type MobileMenuButtonProps = {
  isMenuOpen: boolean;
  toggleMenu: () => void;
};

const MobileMenuButton = ({ isMenuOpen, toggleMenu }: MobileMenuButtonProps) => (
  <div className="sm:hidden">
    <Button
      variant="light"
      onPress={toggleMenu}
      className="text-white"
    >
      {isMenuOpen ? 'Close' : 'Menu'}
    </Button>
  </div>
);

export default MobileMenuButton;
