import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './AlphaEcoSoftware.css';

const AlphaEcoSoftware: React.FC = () => {
  const starContainerRef = useRef<HTMLDivElement>(null);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  useEffect(() => {
    if (starContainerRef.current) {
      const container = starContainerRef.current;
      const starCount = 50;

      for (let i = 0; i < starCount; i++) {
        const star = document.createElement('div');
        star.className = 'eco-star';
        star.style.width = `${Math.random() * 4 + 1}px`;
        star.style.height = star.style.width;
        star.style.left = `${Math.random() * 100}%`;
        star.style.top = `${Math.random() * 100}%`;
        star.style.animationDuration = `${Math.random() * 3 + 2}s`;
        star.style.animationDelay = `${Math.random() * 3}s`;
        container.appendChild(star);
      }
    }
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: [0.6, -0.05, 0.01, 0.99]
      }
    }
  };

  return (
    <div className="alpha-eco-software-container" ref={ref}>
      <div ref={starContainerRef} className="eco-star-container"></div>
      <motion.div
        className="content-wrapper"
        variants={containerVariants}
        initial="hidden"
        animate={controls}
      >
        <motion.div className="text-container" variants={itemVariants}>
          <motion.h2 className="title" variants={itemVariants}>
          Simplify ESG Reporting in Just a Few Clicks
          </motion.h2>
          <motion.p className="description" variants={itemVariants}>
          Alpha Eco's intuitive software transforms ESG complexity into clarity. Our streamlined interface converts daunting compliance tasks into powerful, actionable insights - effortlessly at your fingertips.
          </motion.p>
        </motion.div>
        <motion.div 
          className="image-container"
          variants={itemVariants}
        >
          <motion.div 
            className="image-wrapper"
            variants={itemVariants}
            whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
          >
            <img src="/Generatereportpage.png" alt="Alpha Eco Software Interface" className="software-image" />
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default AlphaEcoSoftware;