import { Navbar, NavbarBrand } from "@nextui-org/react";
import DesktopMenu from './DesktopMenu';
import MobileMenuButton from './MobileMenuButton';

type HeaderProps = {
  isMenuOpen: boolean;
  toggleMenu: () => void;
};

const Header = ({ isMenuOpen, toggleMenu }: HeaderProps) => (
  <Navbar 
    className="bg-transparent fixed top-0 left-0 right-0 z-50 px-4 sm:px-8"
    maxWidth="full"
  >
    <NavbarBrand className="flex-grow basis-1/4">
      <img src="/AELOGO.png" alt="AE Logo" className="w-24 h-24 object-contain mr-2" />
      <p className="font-bold text-white">Alpha Eco</p>
    </NavbarBrand>
    <DesktopMenu />
    <MobileMenuButton isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
  </Navbar>
);

export default Header;