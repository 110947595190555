import React, { useEffect, ReactNode } from 'react';
import { motion, useMotionTemplate, useMotionValue, animate } from 'framer-motion';

const COLORS: string[] = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];

interface AuroraBackgroundProps {
  children: ReactNode;
}

const AuroraBackground: React.FC<AuroraBackgroundProps> = ({ children }) => {
  const color = useMotionValue(COLORS[0]);
  const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #020617 50%, ${color})`;

  useEffect(() => {
    animate(color, COLORS, {
      ease: "easeInOut",
      duration: 10,
      repeat: Infinity,
      repeatType: "mirror",
    });
  }, [color]);

  return (
    <motion.div
      className="min-h-screen flex flex-col relative overflow-hidden"
      style={{ backgroundImage }}
    >
      {children}
      <div className="absolute inset-0 z-0 overflow-hidden pointer-events-none">
        {[...Array(100)].map((_, i) => (
          <div
            key={i}
            className="star"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              width: `${Math.random() * 3 + 1}px`,
              height: `${Math.random() * 3 + 1}px`,
              animationDuration: `${Math.random() * 3 + 2}s`,
              animationDelay: `${Math.random() * 5}s`,
            }}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default AuroraBackground;