import Marquee from 'react-fast-marquee';

const SupportedBy = () => (
  <footer className="py-8">
    <h2 className="text-center text-white text-xl font-semibold mb-4">Supported by</h2>
    <Marquee gradientWidth={50} speed={30}>
      {[
        { name: 'AWSactivate.png', width: 200 },
        { name: 'HKTECH300.png', width: 120 },
        { name: 'NvidiaInception.png', width: 200 },
        { name: 'GoogleCloudStartup.png', width: 250 },
        { name: 'Incubationlogo.png', width: 200 }
      ].map((logo, index) => (
        <div key={index} className="mx-16">
          <img 
            src={`/${logo.name}`} 
            alt={logo.name} 
            style={{ width: `${logo.width}px`, height: '100px', objectFit: 'contain' }} 
          />
        </div>
      ))}
    </Marquee>
  </footer>
);

export default SupportedBy;