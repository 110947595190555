import React, { useRef, useMemo, useEffect } from 'react';
import { motion, useScroll, useAnimation, MotionValue } from 'framer-motion';

interface WordProps {
  word: {
    text: string;
    isGreen?: boolean;
  };
  index: number;
  scrollY: MotionValue<number>;
}

const Word: React.FC<WordProps> = ({ word, index, scrollY }) => {
  const controls = useAnimation();

  useEffect(() => {
    const start = index * window.innerHeight / 3;
    const unsubscribe = scrollY.onChange((latest: number) => {
      if (latest >= start) {
        controls.start({ opacity: 1, scale: 1 });
      } else {
        controls.start({ opacity: 0.3, scale: 0.9 });
      }
    });
    return () => unsubscribe();
  }, [controls, index, scrollY]);

  return (
    <motion.span
      initial={{ opacity: 0.3, scale: 0.9 }}
      animate={controls}
      transition={{ duration: 0.5 }}
      className={`text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold ${
        word.isGreen ? 'text-aurora-green' : 'text-white'
      } mx-2 inline-block`}
    >
      {word.text}
    </motion.span>
  );
};



const ScrollText: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { scrollY } = useScroll();

  const words = useMemo(() => [
    { text: "\n" },
    { text: "\n" },
    { text: "\n" },
    { text: "\n" },
    { text: "Alpha" },
    { text: "Eco" },
    { text: "is" },
    { text: "a" },
    { text: "startup" },
    { text: "\n" },
    { text: "dedicated" },
    { text: "to" },
    { text: "transforming" },
    { text: "the" },
    { text: "\n" },
    { text: "ESG", isGreen: true },
    { text: "reporting" },
    { text: "landscape" },
    { text: "\n" },
    { text: "by" },
    { text: "leveraging" },
    { text: "AI" },
    { text: "technology" },
    { text: "\n" },
    { text: "\n" },
    { text: "🌱" },
    { text: "🌲" },
    { text: "🌲" },
    { text: "🌲" },
    { text: "🌱" },
    { text: "\n" },
    { text: "\n" },
  ], []);

  return (
    <div className="relative" style={{ height: `${words.length * window.innerHeight / 3}px` }}>
      <div 
        ref={containerRef} 
        className="sticky top-0 min-h-screen flex items-center justify-center bg-gray-900 overflow-hidden"
      >
        <div className="max-w-7xl mx-auto px-4 py-16 relative text-center">
          <div className="mb-32"> {/* Added margin bottom for space above leaves */}
            {words.map((word, index) => (
              word.text === "\n" ? (
                <br key={index} />
              ) : (
                <Word
                  key={index}
                  word={word}
                  index={index}
                  scrollY={scrollY}
                />
              )
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollText;