import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const AnimatedList: React.FC = () => {
  const items = [
    "AI-Powered Accuracy: Precise, automated reporting",
    "Compliance Guaranteed -- Meet regulatory standards",
    "Simplified Process: Effortless report generation"
  ];

  const [checkedItems, setCheckedItems] = useState<number[]>([]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const animateItems = (index: number = 0) => {
      if (index < items.length) {
        setCheckedItems(prev => [...prev, index]);
        timeoutId = setTimeout(() => animateItems(index + 1), 1000);
      } else {
        timeoutId = setTimeout(() => {
          setCheckedItems([]);
          timeoutId = setTimeout(() => animateItems(), 500); // Slight pause before restarting
        }, 1000);
      }
    };

    animateItems();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [items.length]);

  return (
    <div className="space-y-3 sm:space-y-4 lg:space-y-6">
      {items.map((item, index) => (
        <motion.div
          key={index}
          className="bg-gray-800 bg-opacity-50 rounded-lg p-2 sm:p-3 lg:p-4 flex items-center h-12 sm:h-16 lg:h-19"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.2 }}
        >
          <motion.div
            className="w-4 h-4 sm:w-5 sm:h-5 lg:w-6 lg:h-6 mr-2 sm:mr-3 lg:mr-4 bg-aurora-green rounded-full flex items-center justify-center flex-shrink-0"
            initial={{ scale: 0 }}
            animate={{ scale: checkedItems.includes(index) ? 1 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <svg className="w-2 h-2 sm:w-3 sm:h-3 lg:w-4 lg:h-4 text-gray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          </motion.div>
          <div className="text-white font-semibold text-xs sm:text-sm lg:text-lg flex-grow text-center">
            {item}
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default AnimatedList;