import { useState } from 'react';
import AuroraBackground from '../components/AuroraBackground';
import Header from '../components/Header';
import MobileMenu from '../components/MobileMenu';
import Hero from '../components/Hero';
import SupportedBy from '../components/SupportedBy';
import ScrollPrompt from '../components/ScrollPrompt';
import ScrollText from '../components/ScrollText';
import ESGReportingComparison from '../components/ESGReportingComparison';
import AlphaEcoSoftware from '../components/AlphaEcoSoftware';
import AIPoweredESGSystem from '../components/AIPoweredESGSystem';
import ESGDataVisualization from '../components/ESGDataVisualization';
import ContactUs from '../components/ContactUs';

const LandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div>
      <Header isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      <MobileMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />

      <AuroraBackground>
        <div className="min-h-screen flex flex-col">
          <Hero />
          <SupportedBy />
          <ScrollPrompt />
        </div>
      </AuroraBackground>

      <section id="about"> 
      <ScrollText /> </section>

      <ESGReportingComparison />

      <section id="features"> 
      <AlphaEcoSoftware /> </section>

      <AIPoweredESGSystem />

      <ESGDataVisualization />

      <section id="contact"> 
      <ContactUs /> </section>

    </div>
  );
};

export default LandingPage;