import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import './EnhancedESGSystem.css';

interface ModuleData {
  title: string;
}

const EnhancedESGSystem: React.FC = () => {
  const topModules: ModuleData[] = [
    { title: 'Descriptive Analysis Document' },
    { title: 'Statistical Document' },
    { title: 'ESG Historical Benchmark' },
  ];

  useEffect(() => {
    const createStars = () => {
      const starContainer = document.querySelector('.esg-star-container');
      if (starContainer) {
        for (let i = 0; i < 50; i++) {
          const star = document.createElement('div');
          star.classList.add('esg-star');
          star.style.left = `${Math.random() * 100}%`;
          star.style.top = `${Math.random() * 100}%`;
          star.style.width = `${Math.random() * 4 + 1}px`;
          star.style.height = star.style.width;
          star.style.animationDuration = `${Math.random() * 3 + 2}s`;
          star.style.animationDelay = `${Math.random() * 3}s`;
          starContainer.appendChild(star);
        }
      }
    };

    createStars();
  }, []);

  return (
    <div className="esg-system-container py-16 px-4 relative overflow-hidden">
      <div className="esg-star-container" />
      <div className="max-w-7xl mx-auto relative z-10">
        <motion.h2 
          className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-center mb-8 sm:mb-16"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <span className="text-white">Complete ESG Reporting with Just 3</span>
          <br />
          <span className="text-green-400">Smart Files</span>
        </motion.h2>
        
        <div className="relative flex flex-col items-center">
          <div className="flex flex-col sm:flex-row justify-between w-full mb-16 sm:mb-32 space-y-4 sm:space-y-0 sm:space-x-4">
            {topModules.map((module, index) => (
              <ShinyModule key={index} title={module.title} color="light-blue" isTop />
            ))}
          </div>
          
          <div className="w-full relative">
            <ConnectingLines />
          </div>
          
          <div className="w-full flex justify-center mt-8 sm:mt-16">
            <ShinyModule title="Alpha Eco Intelligence" isLarge color="green" showLogo />
          </div>
        </div>

        <motion.p
          className="text-center text-white mt-8 sm:mt-16 max-w-6xl mx-auto text-lg sm:text-xl md:text-2xl font-semibold leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5, duration: 0.5 }}
        >
          With these three essential documents,<br />Alpha Eco Intelligence crafts high-quality, 
          compliance-ready ESG reports, converting your data into 
          insightful, comprehensive analyses.
        </motion.p>
      </div>
    </div>
  );
};

interface ShinyModuleProps {
  title: string;
  isLarge?: boolean;
  isTop?: boolean;
  color: 'light-blue' | 'green';
  showLogo?: boolean;
}

const ShinyModule: React.FC<ShinyModuleProps> = ({ title, isLarge = false, isTop = false, color, showLogo = false }) => {
  const overlayClass = color === 'light-blue' ? 'esg-module-overlay-light-blue' : 'esg-module-overlay-green';
  const moduleClass = color === 'green' ? 'esg-module-green' : '';
  
  return (
    <motion.div
      className={`px-4 sm:px-6 py-3 sm:py-4 rounded-lg relative esg-module 
        ${isLarge ? 'w-full sm:w-2/3' : isTop ? 'w-full sm:w-[30%]' : 'w-full sm:w-64'} 
        ${isLarge ? 'h-48 sm:h-64' : 'h-32 sm:h-48'} 
        flex flex-col justify-center items-center overflow-hidden ${moduleClass}`}
      initial={{ "--x": "100%" } as any}
      animate={{ "--x": "-100%" } as any}
      transition={{
        "--x": {
          repeat: Infinity,
          repeatType: "loop",
          duration: 2,
          ease: "linear",
        },
      }}
    >
      <div className="flex items-center justify-center w-full h-full relative z-10">
        {showLogo && (
          <img 
            src="/AELOGO.png" 
            alt="Alpha Eco Logo" 
            className="w-auto h-8 sm:h-12 mr-2 sm:mr-4"
          />
        )}
        <h3 className={`text-white ${isLarge ? 'text-2xl sm:text-4xl' : 'text-lg sm:text-xl'} font-semibold text-center esg-module-title`}>
          {title}
        </h3>
      </div>
      <span className={`block absolute inset-0 rounded-lg p-px esg-module-overlay ${overlayClass}`} />
    </motion.div>
  );
};

const ConnectingLines: React.FC = () => (
  <svg className="absolute w-full h-64 hidden sm:block" style={{ top: '0', left: '0', transform: 'translateY(-50%)' }}>
    <defs>
      <linearGradient id="lineGradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="rgba(200, 162, 255, 0)" />
        <stop offset="45%" stopColor="rgba(200, 162, 255, 1)" />
        <stop offset="55%" stopColor="rgba(200, 162, 255, 1)" />
        <stop offset="100%" stopColor="rgba(200, 162, 255, 0)" />
      </linearGradient>
    </defs>
    {[0, 1, 2].map((index) => (
      <g key={index} className="esg-line-group">
        <line 
          x1={`${25 + index * 25}%`} 
          y1="0" 
          x2="50%" 
          y2="100%" 
          className="esg-connecting-line"
          strokeWidth="1"
        />
        <motion.line 
          x1={`${25 + index * 25}%`} 
          y1="0" 
          x2="50%" 
          y2="100%" 
          stroke="url(#lineGradient)"
          strokeWidth="3"
          className="esg-electricity-flow"
          strokeDasharray="20 80"
          initial={{ strokeDashoffset: 1000 }}
          animate={{ strokeDashoffset: 0 }}
          transition={{
            duration: 5,
            ease: "linear",
            repeat: Infinity,
          }}
        />
      </g>
    ))}
    <motion.line 
      x1="50%" 
      y1="0" 
      x2="50%" 
      y2="100%" 
      stroke="rgba(200, 162, 255, 0.8)"
      strokeWidth="2"
      className="esg-middle-line-overlay"
      strokeDasharray="40 60"
      initial={{ strokeDashoffset: 1000 }}
      animate={{ strokeDashoffset: 0 }}
      transition={{
        duration: 3,
        ease: "linear",
        repeat: Infinity,
      }}
    />
  </svg>
);

export default EnhancedESGSystem;