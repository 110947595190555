import React, { useEffect, useRef, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import './ESGDataVisualization.css';

Chart.register(...registerables);

const ESGDataVisualization: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);
  const barChartRef = useRef<HTMLCanvasElement>(null);
  const pieChartRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    const currentSectionRef = sectionRef.current;

    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      if (barChartRef.current) {
        const barCtx = barChartRef.current.getContext('2d');
        if (barCtx) {
          new Chart(barCtx, {
            type: 'bar',
            data: {
              labels: ['Carbon Emissions', 'Water Usage', 'Waste Reduction'],
              datasets: [{
                label: 'ESG Metrics',
                data: [65, 59, 80],
                backgroundColor: [
                  'rgba(75, 192, 192, 0.6)',
                  'rgba(54, 162, 235, 0.6)',
                  'rgba(153, 102, 255, 0.6)'
                ],
                borderColor: [
                  'rgba(75, 192, 192, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(153, 102, 255, 1)'
                ],
                borderWidth: 1
              }]
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  beginAtZero: true
                }
              }
            }
          });
        }
      }

      if (pieChartRef.current) {
        const pieCtx = pieChartRef.current.getContext('2d');
        if (pieCtx) {
          new Chart(pieCtx, {
            type: 'pie',
            data: {
              labels: ['Diversity', 'Employee Training', 'Community Engagement'],
              datasets: [{
                data: [40, 35, 25],
                backgroundColor: [
                  'rgba(255, 206, 86, 0.6)',
                  'rgba(75, 192, 192, 0.6)',
                  'rgba(153, 102, 255, 0.6)'
                ],
                borderColor: [
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)'
                ],
                borderWidth: 1
              }]
            },
            options: {
              responsive: true,
              maintainAspectRatio: false
            }
          });
        }
      }
    }
  }, [isVisible]);

  return (
    <div ref={sectionRef} className={`esg-data-visualization-container ${isVisible ? 'visible' : ''}`}>
      <div className="data-star-container">
        {[...Array(50)].map((_, i) => (
          <div
            key={i}
            className="data-star"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              width: `${Math.random() * 4 + 1}px`,
              height: `${Math.random() * 4 + 1}px`,
              animationDelay: `${Math.random() * 2}s`
            }}
          />
        ))}
      </div>
      <div className="data-content-wrapper">
        <div className="data-text-container">
          <h2 className="title">ESG Data Insights</h2>
          <p className="slogan">
          Of course, we have dynamic, data-driven charts in our ESG report
          </p>
        </div>
        <div className="charts-container">
          <div className="chart-wrapper">
            <canvas ref={barChartRef} />
          </div>
          <div className="chart-wrapper">
            <canvas ref={pieChartRef} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ESGDataVisualization;