import { motion, AnimatePresence } from 'framer-motion';
import { Link } from "@nextui-org/react";

type MobileMenuProps = {
  isMenuOpen: boolean;
  toggleMenu: () => void;
};

const MobileMenu = ({ isMenuOpen, toggleMenu }: MobileMenuProps) => {
  const menuVariants = {
    closed: { opacity: 0, y: "-100%", transition: { y: { stiffness: 1000 } } },
    open: { opacity: 1, y: 0, transition: { y: { stiffness: 1000, velocity: -100 } } }
  };

  const linkVariants = {
    closed: { opacity: 0, y: 20 },
    open: { opacity: 1, y: 0 }
  };

  return (
    <AnimatePresence>
      {isMenuOpen && (
        <motion.div
          className="fixed top-16 left-0 right-0 bg-black bg-opacity-90 z-40 sm:hidden"
          initial="closed"
          animate="open"
          exit="closed"
          variants={menuVariants}
        >
          <nav className="flex flex-col items-center py-4">
            {['Home', 'About', 'Features', 'Contact'].map((item, index) => (
              <motion.div
                key={item}
                variants={linkVariants}
                initial="closed"
                animate="open"
                exit="closed"
                transition={{ delay: 0.05 * index }}
              >
                <Link
                  href={`#${item.toLowerCase()}`}
                  className="text-white hover:text-aurora-green py-2"
                  onClick={toggleMenu}
                >
                  {item}
                </Link>
              </motion.div>
            ))}
          </nav>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MobileMenu;