import { motion } from 'framer-motion';

const HeroText = () => (
  <div className="w-full md:w-1/2 mb-8 md:mb-0 text-center md:text-left">
    <motion.h1
      className="text-4xl md:text-5xl font-bold mb-4 text-white leading-tight"
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.8, delay: 0.2 }}
    >
      Revolutionize ESG Reporting
      <br />
      <span className="text-aurora-green">
        with AI Efficiency
      </span>
    </motion.h1>
    <motion.p
      className="text-lg md:text-xl mb-8 text-gray-300"
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.8, delay: 0.4 }}
    >
      Transform months of work into minutes: <br />
      Accurate, compliant ESG reports.
      
    </motion.p>
  </div>
);

export default HeroText;