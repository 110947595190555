import HeroText from './HeroText';
import AnimatedList from './AnimatedList';

const Hero = () => (
  <main id="home" className="flex-grow flex items-center justify-center px-4 pt-16">
    <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
      <HeroText />
      <div className="w-full md:w-1/2 md:pl-8">
        <AnimatedList />
      </div>
    </div>
  </main>
);

export default Hero;