import { NavbarContent, NavbarItem, Link } from "@nextui-org/react";

const DesktopMenu = () => (
  <NavbarContent className="hidden sm:flex gap-4 justify-end flex-grow basis-3/4" justify="end">
    {['Home', 'About', 'Features', 'Contact'].map((item) => (
      <NavbarItem key={item}>
        <Link href={`#${item.toLowerCase()}`} className="text-white hover:text-aurora-green">
          {item}
        </Link>
      </NavbarItem>
    ))}
  </NavbarContent>
);

export default DesktopMenu;