import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FileText } from 'lucide-react';

// Expanded dataset (unchanged)
const esgData = [
  { topic: 'CO2 Emissions', unit: 't', min: 1000, max: 5000 },
  { topic: 'Energy Consumption', unit: 'kWh', min: 50000, max: 200000 },
  { topic: 'Water Usage', unit: 'm³', min: 5000, max: 20000 },
  { topic: 'Waste Production', unit: 'kg', min: 1000, max: 10000 },
  { topic: 'GHG Scope 1', unit: 't CO2e', min: 500, max: 2000 },
  { topic: 'GHG Scope 2', unit: 't CO2e', min: 1000, max: 5000 },
  { topic: 'GHG Scope 3', unit: 't CO2e', min: 5000, max: 50000 },
  { topic: 'Renewable Energy', unit: '%', min: 0, max: 100 },
  { topic: 'Diversity Ratio', unit: '%', min: 20, max: 60 },
  { topic: 'Employee Training', unit: 'hours', min: 20, max: 100 },
  { topic: 'Safety Incidents', unit: 'count', min: 0, max: 50 },
  { topic: 'Customer Satisfaction', unit: '%', min: 70, max: 100 },
  { topic: 'Supplier Assessments', unit: 'count', min: 10, max: 100 },
  { topic: 'Community Investment', unit: '$', min: 10000, max: 1000000 },
  { topic: 'ESG Score', unit: '', min: 0, max: 100 },
];

const FileAnimation: React.FC = () => {
  const [visibleFiles, setVisibleFiles] = useState<number[]>([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleFiles(prev => {
        if (prev.length >= 15) {
          return [1]; // Reset to first file
        }
        return [...prev, prev.length + 1];
      });
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="grid grid-cols-5 gap-4 mt-8">
      {[...Array(15)].map((_, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, scale: 0 }}
          animate={visibleFiles.includes(index + 1) ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0 }}
          transition={{ duration: 0.3 }}
          className="flex flex-col items-center"
        >
          <FileText className="text-green-300 mb-2" size={32} />
          <span className="text-white text-sm">Client {index + 1}</span>
        </motion.div>
      ))}
    </div>
  );
};

const ESGReportingComparison: React.FC = () => {
  const [rows, setRows] = useState<Array<{ id: number; topic: string; value: string }>>([]);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);

  const generateRandomValue = (min: number, max: number, unit: string) => {
    const value = Math.floor(Math.random() * (max - min + 1) + min);
    return unit === '%' ? `${value}%` : `${value.toLocaleString()} ${unit}`;
  };

  const updateRows = useCallback(() => {
    const newDataPoint = esgData[Math.floor(Math.random() * esgData.length)];
    const newRow = {
      id: Date.now(),
      topic: newDataPoint.topic,
      value: generateRandomValue(newDataPoint.min, newDataPoint.max, newDataPoint.unit)
    };
    setRows(prev => {
      const updatedRows = [newRow, ...prev];
      if (updatedRows.length > 9) {
        updatedRows.pop();
      }
      return updatedRows;
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(updateRows, 1000);
    return () => clearInterval(interval);
  }, [updateRows]);

  const items = [
    "Time-Draining Data Entry -- Hours lost to manual input",
    "Steep Learning Curve -- Complex interfaces delay implementation",
    "Scalability Struggles -- Inefficient for large client portfolios",
    "Data Inconsistency -- Manual entry prone to errors and discrepancies"
  ];

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const animateItems = (index: number = 0) => {
      if (index < items.length) {
        setCheckedItems(prev => [...prev, index]);
        timeoutId = setTimeout(() => animateItems(index + 1), 1000);
      } else {
        timeoutId = setTimeout(() => {
          setCheckedItems([]);
          timeoutId = setTimeout(() => animateItems(), 500);
        }, 1000);
      }
    };

    animateItems();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [items.length]);

  return (
    <div className="bg-gray-900 py-16">
      <div className="max-w-7xl mx-auto px-4">
        <motion.h2 
          className="text-3xl sm:text-4xl md:text-5xl font-bold text-center mb-16 text-white"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
        
        </motion.h2>
        <motion.h3 
          className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-center mb-16"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <span className="text-white">Elevate Your ESG Reporting</span>
          <br />
          <span className="text-aurora-green">Beyond Spreadsheets</span>
        </motion.h3>

        <div className="flex flex-col md:flex-row justify-between items-stretch mb-16">
          <div className="md:w-1/2 mb-8 md:mb-0 flex flex-col">
            <div className="bg-gray-800 bg-opacity-50 rounded-lg p-2 overflow-hidden flex-grow" style={{ minHeight: '280px' }}>
              <AnimatePresence initial={false}>
                {rows.map((row, index) => (
                  <motion.div 
                    key={row.id}
                    className="grid grid-cols-2 gap-2 mb-1"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20, position: 'absolute', width: 'calc(100% - 1rem)' }}
                    transition={{ duration: 0.3 }}
                  >
                    <motion.div 
                      className="p-1 text-left text-white text-sm font-bold bg-gray-700 rounded"
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.2, delay: 0.1 }}
                    >
                      {row.topic}
                    </motion.div>
                    <motion.div 
                      className="p-1 text-right text-white text-sm bg-gray-700 rounded"
                      initial={{ x: 20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.2, delay: 0.1 }}
                    >
                      {row.value}
                    </motion.div>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>
          </div>
          <div className="md:w-1/2 md:pl-8 space-y-4 flex flex-col justify-between">
            {items.map((item, index) => (
              <motion.div
                key={index}
                className="bg-gray-800 bg-opacity-50 rounded-lg p-5 flex items-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
              >
                <motion.div
                  className="w-5 h-5 mr-3 bg-red-500 rounded-full flex items-center justify-center flex-shrink-0"
                  initial={{ scale: 0 }}
                  animate={{ scale: checkedItems.includes(index) ? 1 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <svg className="w-3 h-3 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </motion.div>
                <div className="text-white font-semibold text-sm">{item}</div>
              </motion.div>
            ))}
          </div>
        </div>
        <motion.h4
          className="text-2xl font-bold text-center mb-8 text-white"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Transform peak reporting periods from chaotic to controlled
        </motion.h4>
        <FileAnimation />
      </div>
    </div>
  );
};

export default ESGReportingComparison;